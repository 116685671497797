.reservation-btn-wrapper {
    cursor: pointer;
}

.preparation-time-wrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    width: 250px;
    margin: 10px auto ;
}

.preparation-time-btn-text {
    width: 100%;
    font-family: Lato-Regular, sans-serif;
    color: rgb(4, 3, 62);
    font-size: 14px;
    line-height: normal;
}

.preparation-time-section-wrapper {
    margin: 15px 10px;
}

.preparation-time-section-wrapper h3 {
    font-family: Cormorant-Bold, serif;
    font-size: 26px;
    margin-bottom: 15px;
}

span#preparation-time-lead {
    font-family: Lato-Regular, serif;
    font-size: 16px;
}

.checkout-sections-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0 !important;
}

.bring-all-together p {
    text-align: left;
}

.checkout-sections-wrapper p {
    max-width: 190px;
    word-break: break-word;
    margin: 0 auto 17px auto;
}

.preparation-options-wrapper > div:first-child {
    border-bottom: 1px solid #d3d3d3;
    margin-bottom: 12px;
}
