body {
    margin: 0;
    font-family: "Lato-Regular", "Cormorant", -apple-system, "Lato Bold",  BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: "Avenir-Book";
    src: local("Avenir-Book"), url(./fonts/Avenir-Book.otf) format('opentype');
}

@font-face {
    font-family: "Avenir-Heavy";
    src: local("Avenir-Heavy"), url(./fonts/Avenir-Heavy.ttf) format('opentype');
}

@font-face {
    font-family: "Avenir-Medium";
    src: local("Avenir-Medium"), url(./fonts/Avenir-Medium.otf) format('opentype');
}

@font-face {
    font-family: "Avenir-Roman";
    src: local("Avenir-Roman"), url(./fonts/Avenir-Roman.otf) format('opentype');
}

@font-face {
    font-family: "Cormorant-Bold";
    src: local("Cormorant-Bold"), url(./fonts/Cormorant-Bold.ttf) format('opentype');
}

@font-face {
    font-family: "Cormorant-Regular";
    src: local('Cormorant-Regular'), url(./fonts/Cormorant-Regular.ttf) format('opentype');
}

@font-face {
    font-family: "Lato-Bold";
    src: local("Lato-Bold"), url(./fonts/Lato-Bold.ttf) format('opentype');
}

@font-face {
    font-family: "Lato-Heavy";
    src: local("Lato-Heavy"), url(./fonts/Lato-Heavy.ttf) format('opentype');
}

@font-face {
    font-family: "Lato-Italic";
    src: local("Lato-Italic"), url(./fonts/Lato-Italic.ttf) format('opentype');
}

@font-face {
    font-family: "Lato-Regular";
    src: local("Lato-Regular"), url(./fonts/Lato-Regular.ttf) format('opentype');
}

@media (min-width: 1920px) {
    body, html {
        max-width: 1920px;
        width: 1920px;
        margin: auto;
    }
}
