.descriptive-radio-btn {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
}

.descriptive-radio-btn  > div {
    margin-left: 29px;
}

.descriptive-radio-btn > div {
    font-family: Lato-Regular, sans-serif;
}

.descriptive-radio-btn label {
    font-family: Lato-Bold, sans-serif;
}

.descriptive-radio-btn label, .descriptive-radio-btn input {
    margin-bottom: 20px;
}

.descriptive-radio-btn > input {
    margin-right: 15px;
    width: 14px;
    height: 14px;

}

.descriptive-radio-btn > input[type="radio"] {
    display: none;
}
.descriptive-radio-btn > input[type="radio"] + *::before {
    content: "";
    display: inline-block;
    vertical-align: center;
    width: 1rem;
    height: 1rem;
    margin-right: 15px;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.1rem;
    border-color: gray;
    border-color: #01003b;
}

.descriptive-radio-btn > input[type="radio"]:checked + *::before {
    background: radial-gradient(#020083 0%, #01003b 40%, transparent 50%, transparent);
    border-color: #01003b;
}
