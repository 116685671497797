.apple-pay-button {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: buy; /* Use any supported button type. */
    width: 329px;
    margin-top: 3vh;
    margin-bottom: 1vh;
    margin-left: 35px;
}
.apple-pay-button-black {
    -apple-pay-button-style: black;
}
.apple-pay-button-white {
    -apple-pay-button-style: white;
}
.apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
}

@media (max-width: 1024px) {
    .apple-pay-button {
        margin-left: 15px;
    }
}