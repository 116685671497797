.deactivate-account-modal {
    margin: auto;
    max-width: 400px;
}

.deactivate-account-modal .password-request-sent-modal-header {
    text-align: left;
    display: flex;
    justify-content: space-between;

}

.deactivate-account-modal div:nth-child(2) h3 {
    font-size: 20px;
    font-weight: bold;
    font-family: Cormorant-Regular, sans-serif;
}

.deactivate-account-modal div:nth-child(2) {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.deactivate-account-modal div#deactivate-btn-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .deactivate-account-modal{
        width: 80% !important;
        min-width: 80% !important;
        min-height: 50vh !important;
        height: 64vh !important;
    }
}