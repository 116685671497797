.restaurant-image {
    max-width: 403px;
    height: 269px;
    max-height: 269px;
    object-fit: cover;
    margin: auto;
}

.restaurant-item-name {
    font-family: Cormorant-Bold, sans-serif;
    font-size: 24px;
    letter-spacing: 0.29px;
    margin-top: 17px;
}

.coming-soon > span:first-child {
    color: #ffffff;
}

@media (max-width: 320px) {
    .restaurant-image {
        padding: 0;
        max-width: 100% !important;
    }
}

.lazy-load-image-background.blur {
    filter: blur(6px);
}
