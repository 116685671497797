.restaurant-item-incoming-reservation {
    width: fit-content;
    padding: 0.25rem 1rem;
    gap:0.25rem;
    border-radius: 15px;
    background-color: #020083;
    transform: translateY(-15px);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center !important;
    margin: auto;
    left: 0;
    right: 0;
    position: absolute;
}

.restaurant-item-reservation-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
}

.restaurant-item-column h3 {
    font-family: Cormorant, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    text-align: center;
    color: #333333;
}

.restaurant-item-column span {
    font-family: Lato-Regular,Cormorant, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.14px;
    text-align: center;
    color: #a8a8a8;
}

.home-page-row .restaurant-item-column {
    margin-bottom: 45px !important;
}
