.payment-done-anon-modal {
    display:flex!important;
    align-items: center;
    width: 20rem;
    max-width: 30rem;
    height: 90vh;
    border-radius: 24px;
    text-align: center;
    margin: auto;
}

.payment-done-anon-modal > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: auto;
}

.payment-done-anon-modal > div > h3 {
    font-family: Cormorant-Bold, serif;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0;
    width: 300px;
    height: 40px;
    text-align: center;
    margin-bottom: 17px;
}

.payment-done-anon-modal > div > p {
    font-family: Lato-Regular, sans-serif;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0;
    width: 260px;
    height: 54px;
    text-align: center;
}

.payment-done-button {
    width: 120px;
    height: 54px;
    border-radius: 33px;
    background-color: #020083;
    margin-right: 0;
}

.send-to-kitchen-modal {
    width: 340px;
    min-width: 340px;
    height: unset;
    margin:auto;
    position: fixed !important;
}

.btn-wrapper {
    display: flex;
}

.create-account-btn-wrapper{
    flex-direction: row!important;
    position: sticky;
    bottom: -2rem;
    padding: 1rem 0 1.5rem 0;
    background-image: linear-gradient(to bottom, rgba(238, 238, 238, 0), rgba(255, 255, 255, 0.94) 66%) !important;
    width: 90%;
    align-items: center;
    justify-content: center;
}

.white-bg {
    background: #fff !important;
}

@supports (-webkit-touch-callout: none) {
    .create-account-btn-wrapper{
        margin-bottom: 60px;
    }

    .create-account-modal-btn{
        width: 200px !important;
    }
 }


.voila-logo {
    width: 85px;
    height: 50px;
    margin-bottom: 10px;
}

.payment-done-img {
    margin-top: 0px;
    width: 150px;
    height: 86px;
    object-fit: cover;
    margin-bottom: 28px;
}

.restaurant-logo {
    width: 200px;
}

#powered-by {
    font-family: Lato-Regular, sans-serif;
    width: 260px;
    height: 20px;
    margin-bottom: 0;
}

#restaurant-title {
    margin-top: 10px;
    font-size: 25px;
    font-weight: bold;
    font-family: 'Cormorant', serif;
    margin-bottom: 0;
}

@media only screen and (max-width: 600px){
    .payment-done-anon-modal{
        max-width: 100% ;
        width: 100% !important;
        height: 100vh;
        border-radius: 0 !important;
    }

    .modal-wrapper > rating-starts-container {
        width: 90%
        } 

    .add-to-order-btn-wrapper{
        position: sticky !important;
        background-image: linear-gradient(to bottom, rgba(238, 238, 238, 0), rgba(255, 255, 255, 0.94) 66%) !important;
        /* padding-bottom: 10px; */
        bottom: 0;
    }
}
