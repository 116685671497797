.booking-heading {
    /*width: 259px;*/
    /*height: 34px;*/
    margin-top: 16px;
    margin-bottom: 48px;
    font-family: Cormorant-Regular, sans-serif;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center !important;
    color: #020083;
}

.booking-restaurant-image {
    max-height: 400px;
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.booking-settings-heading {
    width: 342px;
    height: 34px;
    font-family: Cormorant-Regular, sans-serif;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
    text-align: left !important;
    margin-bottom: 13px;
}

.booking-btn {
    width: 74px;
    height: 42px;
    border-radius: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 10px;
    background-color: #ffffff;
    border: solid 2px #01003b;
}

.booking-powered-by span {
    font-family: Lato-Regular, sans-serif;
}

.booking-powered-by img {
    object-fit: contain;
    width: 150px;
}

select.booking-btn {
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    text-align-last: center;
}

select.booking-btn:focus, select.booking-btn:active {
    outline: none;
}

select.booking-btn.active {
    color: #f5f5f5;
    font-family: Lato-Bold, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.14px;
}

.booking-btn.active {
    background-color: #01003b;
    border: unset;
}

.booking-btn.disabled {
    background-color: grey;
}

.booking-btn-text.active {
    color: #f5f5f5;
    width: unset;
}

.booking-btn-text {
    height: 15px;
    font-family: Lato-Bold, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.14px;
    text-align: center;
    color: #01003b;
}

.booking-btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.booking-btns-2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.booking-submit-btn {
    width: 208px;
    height: 54px;
    border-radius: 33px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.3);
    background-color: #020083;
}

.booking-submit-btn-text {
    width: 170px;
    height: 29px;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    text-align: center;
    color: #f5f5f5;
}

.booking-restaurant-heading {
    font-family: Cormorant-Bold, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
    margin: 1.5rem 0;
}

.booking-restaurant-address {
    /*width: 364px;*/
    font-family: Lato-Regular, Cormorant, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.17px;
    text-align: center;
    color: #a8a8a8;
    margin-top: 16px;
}

.booking-success-text {
    width: 300px;
    height: 100px;
    font-family: Cormorant-Regular, sans-serif;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
}

.booking-success-text span {
    width: 300px;
    height: 100px;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 41px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
}

.booking-success-p span {
    width: 308px;
    height: 54px;
    font-family: Lato-Regular, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
}

.booking-success-p {
    margin: auto;
}

.text-btn {
    width: 146px;
    height: 29px;
    font-family: Cormorant-Regular, sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    text-align: center;
    color: #333333;
    margin: auto;
    cursor: pointer;
}

.booking-calendar {
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-right: 7px;
}

img.booking-calendar.active {
    display: none;
}

.date-picker.active {
    right: unset;
}

.terms-conditions {
    margin-top: 10px;
    text-align: center;
    font-family: Lato-Regular, sans-serif;
    font-size: 12px;
}

.offers-switch-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: Lato-Regular, sans-serif;
}

.offers-switch-container span {
    font-size: 12px;
}

.offers-switch-container p {
    margin: 3px 0;
}

.offers-switch-container > div:first-child {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.booking-settings-column textarea {
    padding: 7px;
    border-radius: 7px;
    font-family: Lato-Regular, sans-serif;
}

@media only screen and (max-width: 762px) {
    .date-picker {
        /*left: 0 !important;*/
        right: 70px !important;
        margin: auto !important;
    }

     div.react-datepicker__triangle {
        display: none !important;
    }

     ul.dropdown-menu {
         max-height: 500px;
         overflow: scroll;
     }
}

@media (max-width: 500px) {
    .date-picker {
        /*left: 0 !important;*/
        right: 100px !important;
    }
}
