.timer-expired-message-modal {
    width: 321px;
    max-width: 350px;
    height: 380px;
    border-radius: 24px;
    text-align: center;
    margin: auto;
    z-index: 99999999;
}

.timer-expired-message-modal-verify-activity-modal{
    height: auto !important;
    z-index: 99999999;
}

.timer-expired-message-modal > div > h3 {
    font-family: Cormorant-Bold, serif;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0;
    width: 300px;
    height: 40px;
    text-align: center;
    margin-bottom: 40px;
}

.timer-expired-message-modal > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: auto;
    margin-bottom: auto;
}

.timer-expired-message-modalmodal > div > p {
    font-family: Lato-Regular, sans-serif;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0;
    width: 260px;
    height: 54px;
    text-align: center;
}

.timer-expired-message-modal-verify-activity-button {
    width: 120px;
    height: 54px;
    border-radius: 33px;
    background-color: #020083;
    margin-right: 0;
    color: white;
    margin-top: 1rem;
}

.timer-expired-message-modal-verify-activity-button:hover {
    background-color: #01003B;
}

.btn-primary:hover{
    background-color: #01003B !important;
    border: 1px solid #01003B !important;
}

.timer-expired-message-modal-clock-img {
    height: 50px;
    width: 50px;
    margin-top: 10px;
    margin-bottom: 15px;
}
