.order-checkout-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
}

.credit-card-form-wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 3px;
}

.credit-card-form-wrapper h4 {
    font-family: Lato-Bold, sans-serif;
    font-size: 21px;
}

/*Override braintree component css*/
.braintree-heading {
    font-family: Lato, sans-serif;
    font-size: 16px;
}

.btn-primary:disabled {
    background-color: gray;
    border-color: gray;
}

.credit-card-type {
    text-align: right;
    justify-content: flex-end;
}

.credit-card-type img {
    width: 30px;
    max-width: 30px;
}


.credit-card-credentials {
    display: flex;
    flex-direction: row;
}

.card-input {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    text-align: left;
    margin: 10px;
    flex: 0 1 100%;
}

.braintree-form__field-group label:first-of-type {
    width: 100%;
    min-width: 100%;
    flex: 0 1 100%;
}

.braintree-form__field-error {
    text-align: left;
}

.braintree-sheet {
    box-shadow: none;
}

.card-input > input {
    width: 85px;;
    max-width: 85px;;
    margin-top: auto;
    padding: 7px;
    font-family: Lato-Regular, sans-serif;
    font-size: 12px;
}

.credentials.card-input:first-child input , .credentials.card-input:nth-child(2) input {
    width: 150px;
    max-width: 180px;
}
.pay-button button {
    width: 80%;
    height: 54px;
    border-radius: 33px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.3);
    background-color: #020083;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    text-align: center;
    color: #f5f5f5;
}

.send-to-kitchen-button button {
    width: 80%;
    height: 54px;
    border-radius: 33px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.3);
    background-color: #020083;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    text-align: center;
    color: #f5f5f5;
}

.checkout-button button {
    width: 80%;
    height: 54px;
    border-radius: 33px;
    border-color: #020083;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.3);
    background-color: #ffffff;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    text-align: center;
    color: #020083;
}
.white-rounded-button {
    height: 54px;
    width: auto;
    border-radius: 33px;
    border-color: #020083;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.3);
    background-color: #ffffff;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    text-align: center;
    color: #020083;
}
.blue-rounded-button {
    height: 54px;
    width: auto;
    border-radius: 33px;
    border-color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.3);
    background-color: #020083;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    text-align: center;
    color: #ffffff;
}

.platfromCharge-button {
    height: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin-left: 5px;
    cursor: pointer;
}
/* .platfromCharge-button > svg:hover{
    background-color: #01003B;
    color: white;
} */

.editTip-button {
    height: 100%;
    border-radius: 33px;
    border: 1px solid #020083;
    background-color: #ffffff;
    font-family: Lato-Regular, Cormorant-Bold, sans-serif;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    text-align: center;
    color: #020083;
    padding: 4px 8px;
    margin-left: 5px;
    cursor: pointer;
}
.editTip-button:hover{
    background-color: #01003B;
    color: white;
}
.checkout-info-wrapper h2 {
    font-family: Cormorant-Regular;
    font-size: 26px;
}

.checkout-info-modifiers{
    margin: 0px;
    font-size: 14px;
    font-style: italic;
    width: calc(65% - 3px);
    color: #A8A8A8;
}

.payment-selection-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.payment-selection-wrapper  > div{
    margin: 0.5rem 0;
}

@media (max-width: 768px) {
    .credit-card-credentials {
        flex-wrap: wrap;
    }
    .payment-selection-wrapper {
        flex-direction: column;
        width: 100%;
        justify-content: center;
    }
    .pay-button button {
        width: 80%!important;
    }
}
.checkout-info-wrapper {
    margin: 1rem 0;
}

.checkout-info-wrapper div *, .checkout-info-wrapper div span {
    font-size:16px;
}

.checkout-info-wrapper div  {
    /* display: flex; */
    align-items: flex-end;
    align-content: flex-end;
    /* text-align: center; */
    text-align: right;
}

.checkout-info-items-wrapper{
    display: flex !important;
}

.checkout-info-wrapper div span:first-child {
    /* color: #A8A8A8; */
    margin-right: 10px;
    text-align: right;
    flex: 2;
}


.checkout-info-wrapper div span:last-child {
    flex: 1;
    text-align: left;
}

#dropin-container {
    border: none !important;
}

div.braintree-method.braintree-method--active {
    border: none
}

div.braintree-card.braintree-form.braintree-sheet {
    /*border: none !important;*/
    /*border-left: 2px solid #d3d3d3 !important;*/
    /*border-bottom: 2px solid #d3d3d3 !important;*/
    border-color: #d3d3d3;
}

div.braintree-sheet__header {
    display: none;
}

.pay-or-kitchen-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pay-button {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pay-all-message > span {
    margin: 0 0 1rem 0;
    font-family: Lato, Cormorant, sans-serif;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333;
    display: block;
}



.pay-by-guest button {
    width: 80%;
    height: 54px;
    border-radius: 33px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.3);
    font-family: Cormorant-Bold, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    text-align: center;
    cursor: pointer;
}

.outline-wrapper button{
    background-color: #ffffff;
    border-color: #020083;
    color: #020083;
}

.google-pay-button button {
    width: 190px;
    height: 54px;
    border-radius: 33px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.3);
    background: #020083 center center no-repeat;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    text-align: center;
    color: #f5f5f5;
}

.google-pay-button button.black {
    background-color: #020083;
}

.google-pay-button button:hover {
    background-color: #007bff !important;
}
.total-amount{
    font-weight: bold;
}
span.totalTip{
    margin: auto 0px;
}

@media (max-width: 768px) {
    .pay-or-kitchen-buttons {
        flex-direction: column;
    }
    .pay-or-kitchen-buttons > .pay-button button {
        width: 80%;
        margin-bottom: 15px;
    }
    .checkout-button button {
        width: 80%;
    }
    .pay-by-guest button {
        width: 80%;
    }
}