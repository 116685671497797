.full-menu-row {
    margin-top: 63.5px;
}

.create-account-modal-btn{
    width: 170px!important;
}

.create-account-modal-btn-text{
    width: 150px!important;
}

.big-round-btn {
    height: 54px;
    width: 120px;
    border-radius: 33px;
    background-color: #020083;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.3);
}

.big-round-btn-2 {
    height: 54px;
    width: 304px;
    max-width: 16rem;
    border-radius: 33px;
    background-color: #020083;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.3);
}

.big-round-btn-auto-width {
    height: 54px;
    width: auto;
    border-radius: 33px;
    font-size: 22px;
    font-family: Cormorant-Bold, sans-serif;
    background-color: #020083;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.3);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    text-align: center;
}

.big-round-btn span, .big-round-btn > a {
    width: 75px;
    height: 29px;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    text-align: center;
    color: #f5f5f5;
}

.create-account-text {
    font-size: 22px !important;
}

.menu-item-img {
    border-radius: 15px;
    max-width: 118px;
    max-height: 114px;
    object-fit: contain;
}

.incoming-reservation {
    width: 648px;
    height: 74px;
    border-radius: 38px;
    background-color: #020082;
    transform: translateY(-37px);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #ffffff;
    margin: 0 auto;
}

.reservation-modify-btn {
    width: 85px;;
    height: 29px;
    font-family: Cormorant-Regular, sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}

.restaurant-reservation-text {
    display: flex;
    justify-content: center;
    align-items: center;
}

.restaurant-reservation-date {
    font-family: Avenir-Book, sans-serif;
    font-size: 12px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    /*line-height: normal;*/
    letter-spacing: 0.13px;
    color: #ffffff;
}

.restaurant-reservation-date > strong {
    font-family: Avenir-Heavy, sans-serif;
    letter-spacing: 0.14px;
}

.restaurant-reservation-date.single-restaurant {
    width: 289px;
    height: 19px;
    font-family: Avenir-Book, sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.15px;
    line-height: 19px;
    color: #ffffff;
    /*display: flex;*/
    /*justify-content: space-evenly;*/
    max-width: 313px;
    /*align-items: center;*/
}

.restaurant-reservation-date.single-restaurant#incoming {
    font-size: 12px;
}

.restaurant-reservation-date.single-restaurant#current {
    font-size: 14px !important;
}

#reservation-pill-label {
    font-family: Avenir-Heavy, sans-serif;
    letter-spacing: 0.17px;
    font-size: 18px;
}

.restaurant-reservation-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-right: 6px;
}

.reservation-order-btn-text {
    width: 81px;
    height: 29px;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 54px;
    letter-spacing: 0.29px;
    text-align: center;
    color: #020082;
    margin-top: auto;
}

.reservation-order-btn-text2 {
    width: 81px;
    height: 29px;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 54px;
    letter-spacing: 0.29px;
    line-height: 29px;
    text-align: center;
    color: #020082;
    /*margin-top: auto;*/
}

.reservation-order-btn {
    width: 160px !important;
    height: 54px;
    border-radius: 33px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.3);
    background-color: #ffffff !important;
    text-align: center !important;
    cursor: pointer;
}

.menage-reservation-btn{
    height: 54px;
    border-radius: 33px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
    background-color: #fff!important;
    cursor: pointer;
    color: #020082;
    font-family: Cormorant-Bold,sans-serif;
    font-size: 24px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 54px;
    letter-spacing: .29px;
    text-align: center;
}

.reservation-order-btn.restaurant-reservation-action-btn {
    width: 170px !important;
    height: 54px;
    border-radius: 33px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.3);
    background-color: #ffffff !important;
    text-align: center !important;
    cursor: pointer;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}

.order-new-items-btn {
    width: 190px;
    height: 54px;
    border-radius: 33px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.3);
    background-color: #ffffff;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.order-new-items-btn-text {
    font-family: Cormorant-Bold, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.29px;
    line-height: 29px;
    text-align: center;
    color: #020082;
    /*margin-top: auto;*/
}

.menu-item-name {
    width: 188px;
    height: 44px;
    font-family: Cormorant-Regular, serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    color: #333333;
}

.menu-item-price {
    width: 73px;
    height: 52px;
    font-family: Cormorant-Bold, serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: right;
    color: #333333;
}

.menu-item-about {
    width: 262px;
    height: 60px;
    font-family: Lato-Regular, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.14px;
    color: #333333;
}

.menu-item-separator {
    width: 100%;
    max-width: 27rem;
    height: 1px;
    border-top: solid 1px #ebebeb;
    margin: auto;
    margin: 0.25rem auto;
}

.menu-title-wrapper {
    width: 382px;
    height: 29px;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: auto auto 20px auto;
}

.menu-title-line {
    display: inline-block;
    width: 58px;
    height: 2px;
    border: solid 1px #ebebeb;
}

.full-menu-type-heading {
    display: flex;
    justify-content: space-between;
    /* margin-top: 16px; */
    cursor: pointer;
    padding: 0.5rem;
}

.full-menu-type-heading:has(.active) {
    border-radius: 6px;
    background-color: rgb(211 211 211 / 30%);
    width: 100%;
}


.full-menu-type-heading h2 {
    font-size: 24px;
    margin: 0.5rem 0;
}

.full-menu-type-heading.first {
    margin-top: 0;
}

.see-full-menu-btn {
    width: 139px;
    height: 54px;
    border-radius: 33px;
    border: solid 2px #979797;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 30px;
    margin-top:20px
}

.see-full-menu-btn-text {
    width: 96px;
    height: 16px;
    font-family: Lato-Regular, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
}

.restaurant-style {
    font-family: Lato-Regular, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.14px;
    text-align: center;
    color: #a8a8a8;
    display: block;
    padding-top: 15px;
}

.new-reservation-link {
    width: 356px;
    height: 21px;
    font-family: Lato-Regular, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.17px;
    text-align: center;
    color: #333333;
    text-decoration: underline;
}

.restaurant-page-row {
    margin-top: 30px;
}

.coming-soon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.restaurant-carousel-images-container {
    min-height: 430px;
}

@media only screen and (max-width: 768px) {
    #reservation-pill-label {
        display: none;
    }
    .mase-reservation {
        display: none;
    }

    .restaurant-page-row {
        margin-top: 12px;
    }

    .incoming-reservation {
        height: 120px;
        min-width: 320px;
        transform: translateY(-55px);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .restaurant-reservation-date.single-restaurant {
        flex-direction: row;
        flex-wrap: nowrap;
        flex: 1 1 auto;
        width: 100% !important;
    }

    .restaurant-actions-btns div {
        margin: 0 0.5em !important;
        padding: 0 !important;
        width: 40% !important;
    }

    .menu-row, .menu-column {
        margin: 0 !important;
        padding: 0 !important;
    }

    .menu-item-img {
        width: 100%;
        height: 10em;
        object-fit: cover;
    }

    .img-column {
        text-align: center !important;
    }

    .incoming-reservation, .menu-title-wrapper {
        width: 300px !important;
    }

    .menu-item-img, .img-column {
        width: 85px;;
        height: auto;
    }

    div.price-column, .menu-item-price {
        width: 40px !important;
        padding: 0 !important;
    }

    .menu-item-description {
        width: 100px !important;
    }

    .menu-item-name {
        width: 170px !important;
        height: auto;
    }

    .menu-item-about {
        width: 100px;
    }

    .restaurant-actions-container {
        position: fixed;
        bottom: 75px !important;
        z-index: 10 !important;
        object-fit: contain;
        width: 100%;
        left: 0 !important;
    }

    .menu-row {
        margin: auto auto 70px auto !important;
    }

    .actions-btns-mask {
        width: 375px;
        height: 136px;
        position: fixed;
        bottom: 0 !important;
        object-fit: contain;
        z-index: 9;
        left: calc((100% - 376px) / 2);
        margin: auto;
        background-image: linear-gradient(to bottom, rgba(238, 238, 238, 0), rgba(255, 255, 255, 0.94) 66%) !important;
    }

    .see-full-menu-btn {
        z-index: 9;
    }

    .menu-item-separator {
        width: 100%;
    }

    .restaurant-carousel-images-container {
        min-height: 270px;
    }
}