.reservation-restaurant-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
}

.reservation-restaurant-item .image-section {
    width: 85px;
    height: 85px;
    border-radius: 15px;
}

.reservation-restaurant-item .image-section img {
    object-fit: cover;
    width: 85px;
    height: 85px;
    border-radius: 15px;
}

.reservation-restaurant-item .details-section {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
}

.details-section h2 {
    font-family: Cormorant-Bold, serif;
    font-size: 24px;
}

.details-section span {
    font-family: Lato-Regular, sans-serif;
    color: gray;
}

.invite-reservation-details {
    font-family: Lato-Regular, sans-serif;
    font-size: 14px;
    margin: 0 auto;
    text-align: center;
    margin-top: 15px;
}

.invite-reservation-details span:first-child {
    color: gray;
}

.invitation-content-wrapper {
    /* height: 100%; */
    /* min-height: 400px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.invitation-header {
    font-family: Cormorant-Regular, sans-serif;
}

.accept-invitation-btn-wrapper{
    background-image: linear-gradient(to bottom, rgba(238, 238, 238, 0), rgba(255, 255, 255, 0.94) 66%) !important;
}

.accept-invitation-btn {
    width: 118px;
    height: 54px;
    margin: 0 25px 0 27px;
    border-radius: 33px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.3);
    background-color: #020083;
    cursor: pointer;
}

.accept-invitation-btn span {
    width: 170px;
    height: 29px;
    margin: 12px 0 13px;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    text-align: center;
    color: #f5f5f5;
}

.decline-invitation-btn {
    width: 118px;
    height: 54px;
    margin: 0 25px 0 27px;
    border-radius: 33px;
    box-shadow: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.decline-invitation-btn span {
    width: 170px;
    height: 29px;
    margin: 12px 0 13px;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    text-align: center;
    color: #000000;
}
