.login-modal {
    width: 404px !important;
    max-height: 724px;
    height: 91vh;
}

.invitation-modal{
    height: 550px !important;
}

.login-header h3 {
    margin-bottom: 0;
}

.login-header {
    margin-top: 14px !important;
}

.login-heading {
    width: 196px;
    height: 68px;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 0.34px;
    text-align: center !important;
    color: #01003b;
    margin: auto;
}

.external-login-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 36px;
    margin-top: 36px;
}

.anon-login-modal {
    border-radius: 20px;
    width: 404px !important;
    min-height: 450px;
    left: calc((100% - 404px) / 2);
}

.external-login-btn {
    width: 44px;
    height: 44px;
    border: solid 2px #d5d5d5;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}

.external-login-btn {
    margin-right: 18px;
}

.external-login-btn:nth-child(4) {
    margin-right: 0;
}

.external-login-img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.login-with {
    width: 71px !important;
    height: 17px;
    margin-right: 16px;
    font-family: Lato-Regular, sans-serif;
    font-size: 14px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
}

.login-footer {
    width: 100%;
    height: 153px;
    background-color: #f5f5f5;
    border-radius: 0 0 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    transform: translateY(16px);
}

.forgot-password-container {
    width: 316px !important;
    height: 46px !important;
    text-align: center !important;
    flex: 0 1 316px;
    margin-bottom: 29px;
    margin-top: 30px;
}

.create-account-container {
    width: 316px !important;
    height: 17px !important;
    text-align: center !important;
    flex: 0 1 316px;
    margin-bottom: 31px;
}


.create-account-container {
    font-family: Lato-Regular, sans-serif;
    font-size: 14px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
}

.forgot-password-container * {
    font-family: Lato-Regular, sans-serif;
    font-size: 14px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
}


.reset-password, .create-account {
    font-weight: bold;
    cursor: pointer;
}

.login-modal form {
    margin-bottom: 10px;
}
.additional-user-modal{
    height: 77vh !important;
}

@media only screen and (max-width: 600px) {
    
    .additional-user-modal, .invitation-modal{
        min-height: 100%;
        max-height: 100% !important;
        border-radius: 0px !important;
        min-width: 100vw !important;
    }

    .login-modal{
        height: 100vh;
        max-height: 100%;
        width: 100% !important;
        border-radius: 0!important;
    }
}
@media only screen and (max-width: 420px) {
    .additional-user-modal, .invitation-modal{
        min-width: 100% !important;
        margin: 0;
    }

    .input-style, .input-info{
        width: 100%;
    }

    .invalid-feedback, .input-label{
        margin-left: 0px;
    }

    .login-footer#footer {
        width: 100%;
        border-radius: 0;
        /* position:absolute; */
        /* bottom:17px;
        right:0px;
        left:0px; */
        overflow-y:auto;
        /* this is the key */
        max-height:100%;
    }
}
