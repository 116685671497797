.reservation-row {
    margin-top: 37px;
}

.reservation-label {
    width: 105px !important;
    height: 17px !important;
    font-family: Lato-Regular, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.17px;
    text-align: right;
    color: #a8a8a8;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.reservation-info {
    flex: 1;
    font-family:  Lato-Regular, Cormorant, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.17px;
    color: #333333;
    display: flex;
    justify-content: space-between;
}

.booking-reservation-info {
    flex: 1;
    height: 17px;
    font-family: Lato-Regular, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.17px;
    color: #333333;
    display: flex;
    justify-content: center;
}

.reservation-info-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
}

.reservation-info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.cancel-reservation-modal {
    width: 404px !important;
    /* height: 272px; */
}

.cancel-reservation-heading span {
    height: 100px;
    text-align: left;
}

.reservation-buttons-container {
    justify-content: flex-start !important;
}

.reservation-restaurant-image-container {
    min-height: 260px;
}

@media (max-width: 600px) {
    .reservation-row {
        margin: auto;
    }
}

@media (max-width: 420px) {
    .cancel-reservation-modal {
        width: 80vw !important;
    }
}

@media all and (max-width: 786px) {
    .reservation-buttons-container {
        text-align: center !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .reservation-buttons-container .reservation-label {
        display: none;
    }

    /* .reservation-buttons-container .reservation-info {
        flex: unset;
    } */
}
