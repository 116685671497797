.menu-types-select {
    border: none;
    background: unset;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    text-align: center;
    background: transparent;
    background-image: url("../../assets/arrow-down.svg");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
    width: 100%;
    display: block;
    margin: 0 auto;
    font-size: 22px;
    color: #000000!important;
}

.menu-types-select:focus, .menu-types-select:active {
    outline: none;
}

.menu-container {
    margin: 1rem 0;
}

.restaurant-logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    margin: auto;
}
.restaurant-logo-wrapper > span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.restaurant-logo-wrapper > span > img {
    max-width: 15rem;
    border-radius: 6px;
}

.side-menu-item-separator {
    width: 100%;
    height: 1px;
    border-top: 1px solid rgb(235, 235, 235);
    margin:0.5rem 0;
}

.MuiDrawer-paper {
    min-width: 80%;
}

.menu-navigation-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row nowrap;
}

.custom-btn-wrapper {
    display: flex;
    justify-content: center;
    margin: 0.5rem 0 2rem;
}


.menu-navigation-wrapper {
    display: flex;
    justify-content: flex-start;
}

.navigation-active-names {
    font-size: 20px;
    margin-left: 10px;
}

.menu-badge-wrapper{
    position: relative;
}

.menu-badge-wrapper .menu-item-badge-container {
    top: 0;
}

.side-menu-badge-wrapper-desctop{
    margin-right: -5px !important;
}


.side-menu-badge-wrapper-mobile{
    display: none !important;
}

@media only screen and (max-width: 762px) {
    .side-menu-badge-wrapper-desctop{
        /* display: none !important; */
        color:rgb(235, 235, 235);
        transform: rotate(0deg)!important;
    }

    .side-menu-badge-wrapper-mobile{
        display: block !important;
        height: 10px;
        width: 10px;
        background-color: #020083;
        position: absolute;
        border-radius: 33px;
        left: 90%;
        top: 50%;
        transform: translateY(-50%);
    }

    .menu-badge-wrapper .menu-item-badge-container {
        top: -45px;
        left: -50px;
    }

    .menu-badge-wrapper .menu-item-badge-container .menu-item-badge{
        font-size: 16px !important;
    }



    .full-menu-row {
        margin-top: 12px;
    }
}


.restaurant-menu-container {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    width: 100%;
    border-radius: 6px;
    cursor: pointer;
}

.restaurant-menu-container:has(.active) {
    background-color: rgb(211 211 211 / 30%);
}

.menu-heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.restaurant-menu-heading {
    font-size: 24px;
    margin: 0.5rem;
}


.restaurant-logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
}

.restaurant-logo-wrapper > img {
    max-width: 15rem;
    border-radius: 6px;
}
