.scrollable-list {
    /*overflow-y: scroll;*/
    max-height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollable-list::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollable-list {
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.circle-btn-base {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.circle-btn-base.active {
    color: #ffffff;
    background-color: #333333;
}

.item-quantity {
    width: 2rem;
    height: 2rem;
    border-radius: 33px;
    background-color: #01003b;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.item-quantity span {
    font-size: 14px;
    font-weight: bold;
    font-family: Lato, Cormorant sans-serif;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    color: #f5f5f5;
}

.add-item-btns {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 19px;
}

.add-menu-item-plus-btn {
    width: 18px;
    height: 24px;
    font-family: Lato-Bold, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.24px;
    text-align: center;
    color: #01003b;
}

.order-next-btn {
    position: fixed;
    bottom: 0;
    height: 100px;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    margin: auto;
    background-image: linear-gradient(to bottom, rgba(238, 238, 238, 0), rgba(255, 255, 255, 0.94) 66%) !important;
    width: 20%;
}

.order-next-btn button {
    width: 118px;
    height: 54px;
    border-radius: 33px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.3);
    background-color: #020083;
    font-family: Cormorant-Bold, sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    text-align: center;
    color: #f5f5f5;
}


@media only screen and (max-width: 762px) {
    .scrollable-list {
        padding: 0rem!important;
    }
    .order-next-btn {
        /* position: fixed; */
        height: 70px;
        bottom: 53px;
        background-image: linear-gradient(to bottom, rgba(238, 238, 238, 0), rgba(255, 255, 255, 0.94) 66%) !important;
        width: 100%;
    }

    .orders-list {
        margin-bottom: 100px;
    }

}
