.user-order-item-button-menu li {
    font-family: Lato-Regular, sans-serif;
    font-size: 16px;
    padding: 5px 0 5px 6px !important;
}

.user-order-item-button-menu li:nth-child(2) {
    border-top: 1px solid rgb(180, 179, 179);
}

.btn.booking-btn.active:active, button.btn.booking-btn.active:focus {
    outline: none !important;
    box-shadow: none !important;
}

.times-available-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    user-select: none;
    flex-wrap: wrap;
    /*box-sizing: border-box;*/
    /*min-width: 100%;*/
    /*float: left;*/
}

.times-available-container div {
    margin: 5px;
}

.dropdown {
    display: flex;
    justify-content: center;
}
/*.times-scrollable::-webkit-scrollbar {*/
/*    width: 10px;*/
/*    height: 10px;*/
/*    opacity: 0.2;*/
/*}*/

/*!* Track *!*/
/*.times-scrollable::-webkit-scrollbar-track {*/
/*    background: transparent;*/
/*}*/

/*!* Handle *!*/
/*.times-scrollable::-webkit-scrollbar-thumb {*/
/*    background: rgba(0,0,0,0.1);*/
/*}*/

/*!* Handle on hover *!*/
/*.times-scrollable::-webkit-scrollbar-thumb:hover {*/
/*    background: rgba(0,0,0,0.4);*/
/*}*/
/*.times-scrollable {*/
/*    overflow-x: auto;*/
/*    -webkit-overflow-scrolling: touch;*/
/*    width: 100%;*/
/*    padding-bottom: 10px;*/
/*}*/

/*.times-available-container div {*/
/*    flex: 1 1 auto;*/
/*    min-width: 100px;*/
/*}*/
/* .times-available-modal {
    position: relative;
} */
.times-available-modal div.booking-submit-btn {
    background: #ffffff;
    color: #01003b;
    border: 1px solid #01003b;
    align-self: flex-end !important;
    justify-self: flex-end !important;
    /*position: absolute;*/
    margin: 20px auto auto auto;
}

.times-available-modal div.booking-submit-btn * {
    color: #01003b;
}

.times-available-type-container h2 {
    font-family: Cormorant-Regular, serif;
    font-size: 26px;
}


@media all and (max-width: 786px) {
    .dropdown > ul.dropdown-menu.user-order-item-button-menu {
        transform: translateX(-100%);
        position: absolute;
    }
}

@media all and (max-width: 600px) {
    .times-available-modal {
        height: 100vh!important;
    }
}