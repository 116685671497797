.add-menu-item-wrapper {
position: relative;
}

.add-menu-item-modal.menu-details-modal {
    height: unset !important;
    /* max-height: unset !important; */
    max-height: 60vh !important;
    padding-bottom: 20px;
}

.add-menu-item-modal {
    width: 404px !important;
    height: 90vh !important;
    /*max-height: 700px !important;*/
    overflow-y: scroll !important;
    background-color: #ffffff;
    padding: 0;
}

.menu-item-modal .menu-item-img {
    width: 105px !important;
    height: 105px !important;
}
.menu-item-modal .menu-item-name {
    width: 168px;
    height: 44px;
}

.menu-item-modal .menu-item-price {
    width: 64px;
    height: 48px;
}

.menu-item-modal .menu-item-about {
    width: 229px;
    height: 55px;
}

.menu-item-modal .img-column {
    padding: 0 !important;
    justify-content: flex-start !important;
    text-align: left !important;
}

.add-menu-item-options-title {
    margin-top: 0.5rem;
}

.add-menu-item-options-title h3 {
    font-family: 'Cormorant', sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
}

.special-requirements-title h4 {
    width: 268px;
    height: 24px;
    font-family: 'Cormorant', sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
    margin: 0 auto;
    margin-top: 0.5rem;
}

.special-requirements-input-wrapper {
    margin-top: 20px;
}

.special-requirements-input {
    width: 356px;
    height: 42px;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #adadad;
    padding-left: 16px;
    padding-top: 13px;
    padding-bottom: 12px;
    margin: auto !important;
}

.edit-remove-wrapper{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: center;
    position: sticky;
    bottom: 0;
}

.menu-item-badge {
    /* width: 24px;
    height: 42px; */
    margin-bottom: 2px;
    width: 28px;
    height: 28px;
    font-family: Lato, 'Cormorant', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 42px; */
    line-height: 28px;
    /*margin: auto;*/
    letter-spacing: 0.29px;
    text-align: center;
    color: #f5f5f5!important;
}

.add-menu-item-submit-btn {
    width: 114px;
    height: 54px;
    background-color: #020083;
    margin: auto;
    /* bottom: 20px; */
    left: calc((100% - 114px) / 2);
    position: absolute;
}

.add-menu-item-submit-btn-text {
    width: 138px;
    height: 29px;
    font-size: 24px;
    letter-spacing: 0.29px;
}

.menu-item-badge-container {
    /* width: 42px;
    height: 42px; */
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #01003b;
    color: #ffffff;
    text-align: center;
    /* transform: translateX(87px) translateY(75px); */
    transform: translateX(5px) translateY(5px);
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 17px;
    z-index: 9;
}

.side-menu-container {
    display: flex;
    align-items: center;
}


.side-menu-badge-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #01003b;
    color: #f5f5f5;
    min-width: 2rem;
    width: 2rem;
    min-height: 2rem;
    height: 2rem;
    border-radius: 50%;
    vertical-align:middle;
    font-family: "Lato";
    font-weight: bold;
    font-size: 14px;
    margin: 0 0.5rem 0 auto;
    /* box-shadow: 2px 4px 8px 2px #888888; */
}

.side-menu-sub-item {
    margin: 0.5rem 0;
    padding: 0.5rem 1rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    flex: 1;
    font-size: 20px;
    cursor: pointer;
}
.side-menu-sub-item > span {
    font-family: Cormorant, Lato, sans-serif;
}

.side-menu-sub-item:has(.active) {
    border-radius: 6px;
    background-color: rgb(211 211 211 / 30%);
}

.side-menu-line {
    display: flex;
    width: 2px;
    height: 24px;
    border-radius: 2px;
    padding-left: -0.1rem;
    margin: 0 0.4rem 0 -0.4rem;
    /* position: absolute;
    top:50%;
    left:2.5%; */
}

.side-menu-line.active {
    background-color: #020083;
}

.side-menu-sub-item > span {
    margin-right: auto;
}

.side-menu-badge-container > .menu-item-badge-container {
    position: relative;
    margin:0 1rem;
    margin-bottom: 0.2rem;
    background-color: #020083;
}

/* Add menu item to order */
.add-menu-item-modal .menu-item-image, .add-menu-item-modal .menu-item-image img {
    width: 97px;
    height: 95px;
    justify-content: center;
}

.add-menu-item-modal .menu-item-name2 {
    width: 155px;
    /* height: 44px; */
}

.add-menu-item-modal .menu-item-price2 {
    width: 60px;
    /* height: 43px; */
}

.add-menu-item-modal .menu-item-about2 {
    width: 217px;
    height: 50px;
}

.add-menu-item-modal .add-menu-item-modal {
    width: 100% !important;
    left: 0 !important;
    top: 0;
    border-radius: unset;
}
/* Add menu item to order */


.menu-item-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.menu-item-details-container img {
    height: 250px;
    max-height: 250px;
    object-fit: cover;
    width: 100%;
    display: inline-block;
}

.menu-item-details-img {
    min-height: 250px;
    max-height: 250px;
    height: 250px;
    object-fit: cover;
    width: 100%;
    display: inline-block;
}

.menu-item-details-container > div {
    padding: 15px 15px 0 15px;
    text-align: center;
}

.circle-close-btn {
    position: fixed;
    z-index: 9999999;
    top:1%;
    right: 2%;
    border-radius: 50%;
    background-color: #ffffff;
    color: #000000;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-circle-close-btn {
    position: absolute;
    top: 1%;
    right:3%;
    border-radius: 50%;
    background-color: #ffffff;
    color: #000000;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-item-details-container > div h3 {
    font-family: Cormorant-Bold, Lato;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: 0;
    color: #333333;
}

.menu-item-details-container > div p {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.14px;
    color: #333333;
}

.menu-item-details-container > div span {
    font-size: 25px;
    font-family: Cormorant;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    color: #333333;
}

@media only screen and (max-width: 762px) {
    .menu-item-badge-container {
        /* width: 42px !important;
        height: 42px !important;
        transform: translateY(55px) translateX(62px) !important; */
        width: 28px !important;
        height: 28px !important;
        transform: translateY(5px) translateX(5px) !important;
        /*position: absolute;*/
        z-index: 100 !important;
    }

    .add-menu-item-modal.menu-details-modal {
        width: 404px !important;
    }

    .side-menu-sub-item > .side-menu-badge-container {
        margin-right: 0.6rem;
    }

    .side-menu-wrapper > .side-menu-badge-container {
        min-width: 1.5rem;
        width: 1.5rem;
        min-height: 1.5rem;
        height: 1.5rem;
        margin: 0.5rem 0.25rem 0.5rem 0;
    }


    .add-menu-item-submit-btn {
        position: unset !important;
        margin-bottom: 20px;
    }

    .menu-details-wrapper > .circle-close-btn {
        bottom: calc(365px - 10px) !important;
        top: 30% !important;
        right: 3px;
    }

    .menu-item-separator{
        width: 100% !important;
    }
}

@media only screen and (max-width: 420px) {
    .add-menu-item-modal.menu-details-modal {
        min-width: 80vw !important;
        max-width: 80vw !important;
    }

    .menu-details-wrapper > .circle-close-btn {
        right: 10%;
    }
}

@media only screen and (max-width: 600px) {
    .add-menu-item-modal {
        max-height: 100% !important;
        height: 100vh !important;
        width: 100% !important;
        border-radius: 0!important;
    }

}

@media only screen and (min-height: 1100px) {
    .add-menu-item-modal {
        height: 75%!important;
    }
}
