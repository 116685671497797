.orders-rounds-wrapper {
    display: flex;
    flex-direction: column;
    /* text-align: center; */
    max-width: 450px;
    margin: auto;
    margin-bottom: 2rem;
    /* in case this wrapper needs to be centered */
}
.orders-rounds-wrapper:has(.order-rounds-empty-reservation){
    margin:auto;
}
/* center this div only if empty reservation case is present */

.orders-rounds-wrapper ul {
    padding: 0;
    margin: 0;
    text-align: left;
}

/* .orders-rounds-wrapper h6 {
    text-align: center;
} */

.sent-to-kitchen-modal .send-to-kitchen-modal-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.send-to-kitchen-modal-buttons > button, .send-to-kitchen-modal-buttons > div {
    flex: 1;
    margin: 2px;
}

.pay-for-others-wrapper > h3 {
    margin-bottom: 15px;
}

.order-amount-error-message{
    color: red;
    margin-bottom: 25px;
}
