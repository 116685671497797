.orders-paid-modal {
    overflow-wrap: break-word;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    text-align: center;
}

.orders-paid-modal * {
    font-family: Lato-Regular, sans-serif;
}

.orders-paid-modal .custom-btn-container * {
    font-family: 'Cormorant', sans-serif;
}

.update-name-input {
    text-align: center;
    width: 100%;
}

.update-name-modal {
    width: auto!important;
    transform: translate(-50%,-50%);
    border-radius: 20px;
    z-index: 9000;
    text-align: center;
}


@media only screen and (max-width: 600px) {
    .orders-paid-modal-container {
        width: 80%!important;
    }
}

@media only screen and (max-width: 420px) {
.update-name-modal {
    height: 100%!important;
    width: 100%!important;
    border-radius: 0!important;
    position: relative;
}
.update-name-modal > div {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 80%;
}
}
