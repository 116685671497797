.custom-modal {
    position: fixed !important;
    z-index: 1030;
    background-color: white;
    width: 60% !important;
    max-width: 500px;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    padding: 32px;
    display: block !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    overflow: scroll;
    border-radius: 1rem;
}

/*.custom-modal::-webkit-scrollbar {*/
/*    display: none;*/
/*    -ms-overflow-style: none;*/
/*}*/

/* Hide scrollbar for Chrome, Safari and Opera */
.custom-modal::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.custom-modal {
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1020 !important;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-back-btn {
    flex: 1;
    max-width: 22px;
    display: inline-flex;
    align-self: center;
    margin-right: 7px;
    cursor: pointer;
}

.times-available-modal {
    position: relative;
    width: 404px !important;
    /* min-height: 676px; */
    height: 90vh;
}

.body-scroll {
    overflow: hidden;
}

.confirmation-link-modal{
    padding: 1rem!important;
    width: 404px !important;
    border-radius: 20px !important;
}

@media only screen and (max-width: 600px) {
    .custom-modal {
        max-width: 100%!important;
        /* left: calc((100%  - 404px) / 2) !important; */
        overflow: scroll;
        /* height: 100%; */
        padding: 32px;
        width: 90% !important;
    }

    .times-available-modal{
        border-radius: 0 !important;
        width: 100% !important;
        min-width: 100vw !important;
        max-width: 100vw !important;
    }
}

@media only screen and (max-width: 420px) {
    .custom-modal {
        min-width: 90vw !important;
        margin: auto;
        overflow: scroll !important;
    }

    .times-available-modal{
        border-radius: 0 !important;
        width: 100% !important;
        min-width: 100vw !important;
        max-width: 100vw !important;
    }
}
