
.menu-item-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 5.5px auto  !important;
    justify-content: center;
    flex: 1;
    /* display: grid;
    grid-template-columns: 2fr 4fr 1fr;
    gap: 0.25rem;
    max-width: 434px;
    cursor: pointer;
    justify-content: center;
    margin: auto; */
    cursor: pointer;
}

.menu-item-image {
    flex: 0 1 118px;
    width: 118px;
    height: 114px;
    object-fit: contain;
    position: relative;
}

.menu-item-image img {
    width: 117px;
    height: 114px;
    border-radius: 15px;
    object-fit: cover;
}

.menu-item-right-column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 15px;
    flex: 0 1 262px;
}

.menu-item-name2 {
    width: 188px;
    /* height: 44px; */
    font-family: Cormorant-Regular, Lato;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: 0;
    color: #333333;
    text-align: left !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}


.menu-item-price2 {
    width: 73px;
    /* height: 52px; */
    font-size: 1.25;
    font-family: Cormorant;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: right;
    color: #333333;
}

.menu-item-about2 {
    width: 262px;
    height: 60px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.14px;
    color: #333333;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.custom-modal.menu-details-modal {
    border: none;
    padding: 0 0 1rem 0;
}

@media only screen and (max-width: 762px) {
    .menu-item-image, .menu-item-image img {
        width: 97px;
        height: 95px;
    }

    .menu-item-name2 {
        width: 155px;
        /* height: 44px; */
    }

    .menu-item-price2 {
        width: 60px;
        /* height: 43px; */
    }

    .menu-item-about2 {
        width: 217px;
        height: 50px;
    }

}
